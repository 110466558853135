import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const FETCH_NO_OF_BLOGS = `${API_URL}/blogs/count`
export const FETCH_NO_OF_KEYWORDS = `${API_URL}/keyword/count`

// function to fetch token from database
function fetchTokenFromDB() {
  const tokenObj = localStorage.getItem('kt-auth-react-v')
  let token = ''

  if (tokenObj) {
    try {
      const parsedTokenObj = JSON.parse(tokenObj)
      token = parsedTokenObj.token || ''
    } catch (error) {
      console.error('Error parsing token object:', error)
    }
  }

  return token
}

// fetching no. of blogs in db
export async function fetchBlogCount() {
  const token = fetchTokenFromDB()
  if (token) {
    try {
      const response = await axios.get(FETCH_NO_OF_BLOGS, {
        headers: {
          'auth-token': token,
        },
      })
      return response
    } catch (error) {
      throw error
    }
  } else {
    throw new Error('Token not found')
  }
}

// fetching no. of keywords in db
export async function fetchKeywordsCount() {
  const token = fetchTokenFromDB()
  if (token) {
    try {
      const response = await axios.get(FETCH_NO_OF_KEYWORDS, {
        headers: {
          'auth-token': token,
        },
      })
      return response
    } catch (error) {
      throw error
    }
  } else {
    throw new Error('Token not found')
  }
}
