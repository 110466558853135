import {FC} from 'react'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {fetchBlogCount, fetchKeywordsCount} from '../../modules/dashboard/core/_request'
import Loader from '../../modules/loader/Loader'
import MessageComponent from '../../modules/message/MessageComponent'

const DashboardPage: FC = () => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const [loading, setloading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [blogCount, setBlogCount] = useState<number>()
  const [keywordCount, setKeywordCount] = useState<number>()

  const handleFetchBlogsCount = async () => {
    setloading(true)
    try {
      const res = await fetchBlogCount()
      setBlogCount(res.data.count)
      setloading(false)
    } catch (error: Error | unknown) {
      setIsError(true)
      setloading(false)
      notifyError((error as Error).message)
    }
  }

  const handleFetchKeywordsCount = async () => {
    setloading(true)
    try {
      const res = await fetchKeywordsCount()
      setKeywordCount(res.data.count)
      setloading(false)
    } catch (error: Error | unknown) {
      setIsError(true)
      setloading(false)
      notifyError((error as Error).message)
    }
  }

  useEffect(() => {
    handleFetchBlogsCount()
    handleFetchKeywordsCount()
  }, [])

  const handleShowCounts = (loading: boolean) => {
    switch (loading) {
      case true:
        return <Loader />
      case false:
        return (
          <>
            {isError ? (
              <MessageComponent message='An error occured. Please try again later' />
            ) : (
              <div className='row m-0'>
                {/* blogs count */}
                <div className='card col-md-4 me-md-4 mb-4 mb-md-0'>
                  <div className='card-body'>
                    {blogCount === 0 ? (
                      <p className='card-text'>No blogs found</p>
                    ) : (
                      <>
                        <h1 className='text-primary'>{blogCount}</h1>
                        <p className='card-text'>Blogs have been published</p>
                      </>
                    )}
                  </div>
                </div>
                {/* keywords count */}
                <div className='card col-md-4'>
                  <div className='card-body'>
                    {keywordCount === 0 ? (
                      <p className='card-text'>No keywords found</p>
                    ) : (
                      <>
                        <h1 className='text-primary'>{keywordCount}</h1>
                        <p className='card-text'>Keywords have been created</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )
      default:
        return null
    }
  }

  return <>{handleShowCounts(loading)}</>
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
